import React, { createContext, useState, useContext } from 'react';

const UserLocationContext = createContext(null);

export const useUserLocation = () => {
    return useContext(UserLocationContext);
};

export const UserLocationProvider = ({ children }) => {
    const [userLocation, setUserLocation] = useState({
      address: null,
      lat: null,
      lng: null
    });

    return (
        <UserLocationContext.Provider value={[ userLocation, setUserLocation ]}>
            {children}
        </UserLocationContext.Provider>
    );
};
