import { useEffect, useState } from 'react';

// Router
// https://github.com/molefrog/wouter
import { Route, Switch, useLocation, useRouter } from 'wouter';

// Penpal: communicating with parent page
import { connectToParent } from 'penpal';

// Google Maps API
import { LoadScript } from '@react-google-maps/api';

// Google Tag Manager
import TagManager from 'react-gtm-module';

// Views
import Home from './views/Home/Home';
import Ashtray from './views/Ashtray/Ashtray';

// Context
import { UserLocationProvider } from './UserLocationContext';
import { AshtraysProvider } from './AshtraysContext';
import { FilteredAshtraysProvider } from './FilteredAshtraysContext';
import { DirectionsProvider } from './DirectionsContext';

import './App.scss';

function App() {
  // Google Maps API key
  const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const libraries = ["places", "geometry"];

  // Initialise Google Tag Manager
  const tagManagerOptions = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
  };
  TagManager.initialize(tagManagerOptions);

  // Router
  const [ location, setLocation ] = useLocation();
  // const {_, push} = useRouter();
  
  const [ userLoc, setUserLoc ] = useState(null);

  // FROM website
  // Establish connection with website and receive website URL updates
  // http://localhost:8888/embed?ashtray=bondi-beach-promenade-bottom-carpark-stairs
  const connection = connectToParent({
    methods: {
      setAppUrl(params) {
        // Update the React app URL based on the received parent website URL params
        if(params && params.ashtray){
          const newAppUrl = `/ashtrays/${params.ashtray}`;
          window.history.pushState({}, '', newAppUrl);
        }
        if(params && params.userLoc){
          setUserLoc(params.userLoc);
        }
      }
    }
  });

  // TO WEBSITE
  // Monitor app URL changes and send updates to website
  useEffect(() => {
    // Notify the parent about the URL change
    connection.promise.then(parent => {
      const ashtray = location.split("/ashtrays/")[1];
      const websiteParams = {
        'ashtray': ashtray ? ashtray : null,
        // 'userLoc': 
      };
      parent.updateWebsiteUrlParam(websiteParams);
    });
  }, [location, window.location.search]);


  return (
    <div>
      <LoadScript
          googleMapsApiKey={googleMapsAPIKey}
          libraries={libraries}
      >
      <UserLocationProvider>
      <AshtraysProvider>
      <FilteredAshtraysProvider>
      <DirectionsProvider>
        <Switch>
          <Route path="/" component={null} children={(params) => <Home userLoc={userLoc} />} />
          <Route path="/ashtrays/:id" children={(params) => <Ashtray userLoc={userLoc} />} />
        </Switch>
      </DirectionsProvider>
      </FilteredAshtraysProvider>
      </AshtraysProvider>
      </UserLocationProvider>
      </LoadScript>
    </div>
  );
}

export default App;
