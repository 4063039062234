import React, { useEffect, useRef, useState } from "react";

import { useUserLocation } from "../../UserLocationContext";
import { useAshtrays } from "../../AshtraysContext";
import { useFilteredAshtrays } from "../../FilteredAshtraysContext";

// Components
import PageQRCode from "../PageQRCode/PageQRCode";

import { convertHeicToJpg, humaniseDistance } from "../../utilities";
import { ThreeDots } from "react-loader-spinner";
import { point } from "@turf/helpers";
import distance from "@turf/distance";
import moment from "moment";

import styles from "./Detail.module.scss";

const Detail = (props) => {
  const { ashtrays, loading } = useAshtrays();
  const [userLocation] = useUserLocation();
  const { filteredAshtrays, setFilteredAshtrays } = useFilteredAshtrays();
  const ashtrayID = props.ashtrayID;

  const detailContainerRef = useRef(null);
  const detailRef = useRef(null);

  const [photoLoading, setPhotoLoading] = useState(true);
  const [surroundingsPhoto, setPhoto] = useState(null);

  useEffect(() => {}, []);

  useEffect(() => {
    // Do something when ashtray data is fetched
    if (filteredAshtrays && filteredAshtrays.length > 0) {
      setPhoto(null);
      convertHeicToJpg(
        filteredAshtrays[0].properties.photo.surroundings.url,
        setPhoto,
        setPhotoLoading
      );
    }
  }, [filteredAshtrays]); // Listening for changes in the ashtrays state

  useEffect(() => {
    if (ashtrayID && !loading) {
      // filter list
      setFilteredAshtrays(
        ashtrays
          .filter((feature) => feature.properties.id === ashtrayID)
          .map((ashtray) => {
            if (userLocation.lat) {
              // Convert userLocation to a turf point
              const userPoint = point([userLocation.lng, userLocation.lat]);
              const ashtrayPoint = point(ashtray.geometry.coordinates);
              const d = distance(userPoint, ashtrayPoint, { units: "meters" });
              const humanised_d = humaniseDistance(d);
              // Store calculated distance as value in meters and human readable format
              ashtray.properties.distance = d;
              ashtray.properties.distance_humanised = humanised_d;
            }
            return ashtray;
          })
      );
    }
  }, [loading, userLocation, ashtrays]);

  if (loading) {
    return (
      <div ref={detailContainerRef} style={{ width: "100%", height: "400px" }}>
        Loading...
      </div>
    );
  }

  if (!filteredAshtrays || filteredAshtrays.length === 0) {
    return <div ref={detailContainerRef}>No ashtrays found</div>;
  }

  function humaniseDate(dateString) {
    const inputDate = moment(dateString);
    const now = moment();

    const daysDiff = now.diff(inputDate, "days");

    if (daysDiff === 0) {
      return "Today";
    } else if (daysDiff === 1) {
      return "1 day ago";
    } else {
      return `${daysDiff} days ago`;
    }
  }

  const ashtray = filteredAshtrays[0];

  return (
    <div ref={detailContainerRef} className={styles.ashtrayDetailContainer}>
      <div ref={detailRef}>
        {userLocation.lat && (
          <div className={styles.distance}>
            {ashtray.properties.distance_humanised
              ? ashtray.properties.distance_humanised
              : ""}
          </div>
        )}
        <div className={styles.imageCrop}>
          {photoLoading ? (
            <div className={styles.imageLoading}>
              <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                }}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <img
              className={styles.image}
              src={surroundingsPhoto}
              alt={"Photo of ashtray at " + ashtray.properties.name}
            />
          )}
        </div>
        <h2 className={styles.ashtrayName}>{ashtray.properties.name}</h2>
        <p>*Updated {humaniseDate(ashtray.properties.date)}</p>

        <div className={styles.shareContainer}>
          {/* <button className={styles.shareButton}>Share</button> */}
          <h4 className={styles.shareHeading}>
            Are you a venue? Use this QR code to direct people to this ashtray
          </h4>
          <PageQRCode />
        </div>

        <div className={styles.reportContainer}>
          <p className={styles.reportLabel}>Issue with this ashtray?</p>
          <a
            href="https://virgen276.preview.softr.app/report-an-ashtray-issue?show-toolbar=true"
            className={styles.reportButton}
          >
            Report
          </a>
        </div>
      </div>
    </div>
  );
};

export default Detail;
