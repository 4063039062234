import { useEffect } from "react";

// Components
import Search from "../../components/Search/Search";
import Map from "../../components/Map/Map";
import Detail from "../../components/Detail/Detail";

// Router
import { useRoute } from "wouter";
// Penpal: communicating with parent page
import { connectToParent } from 'penpal';

import { useUserLocation } from "../../UserLocationContext";

import { calculateBounds } from "../../utilities";


import styles from "./Ashtray.module.scss";

function Ashtray(props) {
  const [match, params] = useRoute("/ashtrays/:id");
  const ashtrayID = params ? params.id : null;
  const userLoc = props.userLoc;

  const [userLocation, setUserLocation] = useUserLocation();

  useEffect(() => {
    const appUrlParams = new URLSearchParams(window.location.search);
    const userLoc = appUrlParams.get("userLoc");
    if (userLoc && !userLocation.lat) {
      const lat = parseFloat(userLoc.split(",")[0]);
      const lng = parseFloat(userLoc.split(",")[1]);
      const bound = calculateBounds(lat, lng);

      setUserLocation({
        address: "",
        lat,
        lng,
        bound,
      });
    }
  }, [window.location.search]);

  const connection = connectToParent();
  
  const handleBackButton = () => {
    connection.promise.then(parent => {
      parent.clearAshtrayID();
    });
  }

  return (
    <div>
      <a href={`/${userLocation.lat ? '?userLoc='+userLocation.lat+','+userLocation.lng : ''}`} onClick={handleBackButton} className={styles.backButton}>
        <span className={styles.backButtonBack}>&laquo; Back</span>
        <span className={styles.backButtonLabel}>to the ashtray map</span>
      </a>
      <Map ashtrayID={ashtrayID} userLoc={userLoc} />
      {/* don't show locate if the user is already located */}
      {/* {!userLocation.lat && ( */}
      <Search
        directions={true} // Search should also get walking directions
        updateResults={false} // Prevent the search from updating the result when on ashtray page
        ashtrayID={ashtrayID}
        userLoc={userLoc}
        showInput={false}
      />
      {/* )} */}
      <Detail ashtrayID={ashtrayID} />
    </div>
  );
}

export default Ashtray;
