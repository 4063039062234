import { SEARCH_RADIUS } from "./settings";
import heic2any from 'heic2any';
import humanFormat from "human-format";

/**
 * Calculate the bounds of a square centered on a given latitude and longitude.
 *
 * @param {number} lat - The latitude of the center coordinate.
 * @param {number} lng - The longitude of the center coordinate.
 * @param {number} size - The size of each side of the square in meters. Default is 200m.
 * @returns {Object} An object representing the north, south, east, and west bounds.
 */
export function calculateBounds(lat, lng, size = SEARCH_RADIUS) {
  // Convert size to a fraction of degree
  const deltaLat = (size / 2) / 111320;
  const deltaLng = (size / 2) / (111320 * Math.cos((Math.PI / 180) * lat));

  return [
      [lng + deltaLng, lat + deltaLat],
      [lng - deltaLng, lat - deltaLat]
  ];
}

/**
 * Calculate the centroid and bounding box of a set of GeoJSON Point features.
 *
 * @param {Array} features - An array of GeoJSON Point features.
 * @returns {Object|null} An object containing the centroid and bounds, 
 *                        or null if the features array is empty.
 * @returns {Object} .centroid - The calculated centroid.
 * @returns {number} .centroid.x - The average longitude of all points.
 * @returns {number} .centroid.y - The average latitude of all points.
 * @returns {Object} .bounds - The bounding box encompassing all points.
 * @returns {number} .bounds.minX - Minimum longitude value.
 * @returns {number} .bounds.minY - Minimum latitude value.
 * @returns {number} .bounds.maxX - Maximum longitude value.
 * @returns {number} .bounds.maxY - Maximum latitude value.
 */
export function calculateCentroidAndBounds(features) {
  if (features.length === 0) {
      return null;
  }

  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  let sumX = 0;
  let sumY = 0;

  features.forEach(feature => {
      const [x, y] = feature.geometry.coordinates;
      
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);

      sumX += x;
      sumY += y;
  });

  const centroid = {
      x: sumX / features.length,
      y: sumY / features.length
  };

  const bounds = {
      minX,
      minY,
      maxX,
      maxY
  };

  return { centroid, bounds };
}

/**
 * Converts a HEIC format image file to a JPG format image.
 * 
 * @param {File} heicFile - The HEIC image file to be converted.
 * @param {Function} setPhoto
 * @param {Function} setLoading
 * @returns {Promise<File>} A promise that resolves with the converted JPG image file.
 */
export async function convertHeicToJpg(imgUrl, setPhoto, setLoading) {
    // Check if the URL ends with '.jpg' or '.jpeg'
    if (imgUrl.toLowerCase().endsWith('.jpg') || imgUrl.toLowerCase().endsWith('.jpeg')) {
      return imgUrl; // If it's already a JPG, simply return the URL
    }
    try {
      // Fetch the image as a blob
      const response = await fetch(imgUrl);
      const blob = await response.blob();

      if (blob.type === "image/jpeg" || blob.type === "image/png") {
        setPhoto(imgUrl)
        return;
      }

      // Ensure the blob is a HEIC type
      if (blob.type !== "image/heic") {
          console.error("The provided URL does not point to a HEIC image.", blob.type);
          return;
      }

      // Convert the blob to JPG using heic2any
      const output = await heic2any({
          blob: blob,
          toType: "image/jpeg",
          quality: 0.8
      });

      // Use the resulting blob (for demonstration purposes, we're just setting it as a source for an img element)
      const outputImgUrl = URL.createObjectURL(output);
      // document.getElementById("yourImageElementID").src = outputImgUrl;
      setPhoto(outputImgUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Generate native map app links based on user and ashtray loation.
  **/
  export const getMapAppLinks = (userLocation, ashtrayLocation) => {
    const appleDirectionsLink = 'https://maps.apple.com/?saddr=';
    const googleDirectionsLink = 'https://www.google.com/maps?saddr=';
    const appleLocationLink = 'https://maps.apple.com/?ll=';
    const googleLocationLink = 'https://www.google.com/maps?q=';
    // debugger
    if(userLocation && ashtrayLocation) {
        return { 
            'apple': `${appleDirectionsLink}${userLocation.lat},${userLocation.lng}&daddr=${ashtrayLocation.lat},${ashtrayLocation.lng}&dirflg=w`, 
            'google': `${googleDirectionsLink}${userLocation.lat},${userLocation.lng}&daddr=${ashtrayLocation.lat},${ashtrayLocation.lng}&directionsmode=walking`
        }
    } else if(userLocation && !ashtrayLocation) {
        return { 
            'apple': `${appleLocationLink}${userLocation.lat},${userLocation.lng}`,
            'google': `${googleLocationLink}${userLocation.lat},${userLocation.lng}`
        }
    } else if(!userLocation && ashtrayLocation) {
        return { 
            'apple': `${appleLocationLink}${ashtrayLocation.lat},${ashtrayLocation.lng}`,
            'google': `${googleLocationLink}${ashtrayLocation.lat},${ashtrayLocation.lng}`
        }
    } else {
        return null;
    }
  };
  
  /**
   * Converts distance value in meters into human readable string.
  **/
  export const humaniseDistance = (distance) => {
    const distanceScale = new humanFormat.Scale({
      m: 1,
      km: 1000
    });
    const d = humanFormat(distance, { scale: distanceScale, maxDecimals: 0}).replace(" ", "");
    return d;
  }