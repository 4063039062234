import React, { useState } from "react";

// Components
import Map from "../../components/Map/Map";
import List from "../List/List";

// Styles
import styles from "./Results.module.scss";

function Results(props) {
  const userLoc = props.userLoc;
  // State to keep track of the active tab.
  const [activeTab, setActiveTab] = useState("Map");

  return (
    <div className={styles.results}>
      <div className={styles.tabs}>
        <button
          data-active={activeTab === "Map"}
          onClick={() => setActiveTab("Map")}
          disabled={activeTab === "Map"}
        >
          Map
        </button>
        <button
          data-active={activeTab === "List"}
          onClick={() => setActiveTab("List")}
          disabled={activeTab === "List"}
        >
          List
        </button>
      </div>

      {/* Tab Content */}
      <div className={styles.tabContent}>
        <div
          data-active={activeTab === "Map"}
          className={styles.panel}
          data-panel="map"
        >
          <Map userLoc={userLoc} />
        </div>
        <div
          data-active={activeTab === "List"}
          className={styles.panel}
          data-panel="list"
        >
          <List userLoc={userLoc} />
        </div>
      </div>
    </div>
  );
}

export default Results;
