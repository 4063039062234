import Airtable from 'airtable';
import slugify from 'react-slugify';

const apiKey = process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN;
const appID = process.env.REACT_APP_AIRTABLE_APP_ID;
const base = new Airtable({ apiKey }).base(appID);

export const fetchRecords = async (tableName) => {
   try {
      const records = await base(tableName).select({
         // maxRecords: 100, 
         view: "Grid view" 
      }).all();

      // Convert data to GeoJSON
      const features = records.map(record => record.fields).map(record => {
         return {
            type: "Feature",
            properties: {
               id: slugify(record["Ash tray name"]),
               name: record["Ash tray name"],
               contact: record["Contact info"],
               photo: {
                  ashtray: record["Photo - ashtray"] ? record["Photo - ashtray"][0] : null,
                  surroundings: record["Photo - surroundings"] ? record["Photo - surroundings"][0] : null
               },
               distance: null,
               date: record["Created"]
            },
            geometry: {
               coordinates: [
                  parseFloat(record["Longitude Calc"]),
                  parseFloat(record["Lat Calc"])
               ],
               type: "Point"
            },
         }
      })
      
      return features;

   } catch (error) {
      console.error("Error fetching records: ", error);
      throw error;
   }
};
