import React, { useState, useEffect } from "react";
import { Link } from "wouter";
import { ThreeDots } from "react-loader-spinner";
import styles from "./ListItem.module.scss";
import { useFilteredAshtrays } from "../../FilteredAshtraysContext";
import { convertHeicToJpg } from "../../utilities";

function ListItem({ ashtray, userLoc }) {
  const { filteredAshtrays } = useFilteredAshtrays();

  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Do something when ashtray data is fetched
    if (ashtray) {
      setPhoto(null);
      convertHeicToJpg(
        ashtray.properties.photo.surroundings.url,
        setPhoto,
        setLoading
      );
    }
  }, [ashtray]); // Listening for changes in the ashtrays state
  return (
    <Link
      href={`/ashtrays/${ashtray.properties.id}${
        userLoc ? "?userLoc=" + userLoc : ""
      }`}
      className={styles.ashtrayLink}
    >
      {filteredAshtrays && (
        <div className={styles.distance}>
          {ashtray.properties.distance
            ? Math.round(ashtray.properties.distance) + "m"
            : ""}
        </div>
      )}
      <div className={styles.imageCrop}>
        {loading ? (
          <div className={styles.imageLoading}>
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#3A0DEE"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
              visible={true}
            />
          </div>
        ) : (
          <img
            className={styles.image}
            src={photo}
            alt={ashtray.properties.name}
          />
        )}
      </div>
      <hr className={styles.ashtrayLine} />
      <h3 className={styles.ashtrayName}>{ashtray.properties.name}</h3>
    </Link>
  );
}

export default ListItem;
