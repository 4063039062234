import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode';

// Penpal: communicating with parent page
import { connectToParent } from 'penpal';

const PageQRCode = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const connection = connectToParent();

    connection.promise.then(parent => {
      parent.getCurrentUrl().then(websiteUrl => {
        generateQRCode(websiteUrl);
      });
    });
  }, [window.location.href]);

  const generateQRCode = (websiteUrl) => {
    if (canvasRef.current) {
      QRCode.toCanvas(canvasRef.current, websiteUrl, function (error) {
        if (error) console.error(error);
      });
    }
  };

  return <canvas ref={canvasRef}></canvas>;
};

export default PageQRCode;
