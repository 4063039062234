import React, { useRef, useEffect, useState } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import TagManager from "react-gtm-module";
import { point } from "@turf/helpers";
import distance from "@turf/distance";
import { useLocation } from "wouter";
// Penpal: communicating with parent page
import { connectToParent } from "penpal";

import styles from "./Search.module.scss";
import { useUserLocation } from "../../UserLocationContext";
import { useAshtrays } from "../../AshtraysContext";
import { useFilteredAshtrays } from "../../FilteredAshtraysContext";
import { useDirections } from "../../DirectionsContext";

import { calculateBounds, humaniseDistance } from "../../utilities";
import { SEARCH_RADIUS } from "../../settings";

const libraries = ["places", "geometry"];

const Search = (props) => {
  // Whether or not to produce directions
  const calcDirections = props.directions ? props.directions : false;

  // Whether or not to update search results.
  // Don't update result (filteredAshtrays) on Ashtray page
  const updateResults =
    props.updateResults === false ? props.updateResults : true;

  // show input by default unless the prop says otherwise
  const showInput = props.showInput === undefined ? true : props.showInput;

  // Google Maps API key
  const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const searchBoxContainerRef = useRef();
  const searchBoxRef = useRef();
  const [userLocation, setUserLocation] = useUserLocation();
  const { ashtrays } = useAshtrays();
  const { filteredAshtrays, setFilteredAshtrays } = useFilteredAshtrays();
  const [directions, setDirections] = useDirections();
  const [location, push] = useLocation();

  const [directionsService, setDirectionsService] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  const connection = connectToParent();

  const onSearchBoxLoaded = (ref) => {
    searchBoxRef.current = ref;
  };

  const pushUserLocationToWebsite = (lat, lng) => {
    const queryParams = new URLSearchParams();
    queryParams.set("userLoc", `${lat},${lng}`);
    // queryParams.set('ashtrayLoc', `${destination.lat},${destination.lng}`);
    push(`${location}?${queryParams.toString()}`);
  };

  // Set user location to the selected place search result
  const onPlacesChanged = () => {
    const place = searchBoxRef.current.getPlaces()[0];
    const address = place.formatted_address;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const bound = calculateBounds(lat, lng);
    // userLoc = null;
    setUserLocation({
      address,
      lat,
      lng,
      bound,
    });
    pushUserLocationToWebsite(lat, lng);
  };

  // Set user location to geolocation result
  const handleGeolocation = () => {
    // GTM payload for click event
    const dataLayer = {
      event: "Button click",
      category: "Search",
      action: "Click",
      label: "Geolocation",
    };
    // Send event to GTM
    TagManager.dataLayer({ dataLayer });

    navigator.geolocation.getCurrentPosition((position) => {
      // userLoc = null;
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const bound = calculateBounds(lat, lng);
      setUserLocation({
        address: "",
        lat,
        lng,
        bound,
      });
      pushUserLocationToWebsite(lat, lng);
    });
  };

  useEffect(() => {
    if (ashtrays && ashtrays.length > 0) {
      // Set user location to the userLoc param from parent website URL
      if (props.userLoc) {
        const userLoc = props.userLoc.split(",").map((v) => parseFloat(v));
        const bound = calculateBounds(userLoc[0], userLoc[1]);
        setUserLocation({
          address: "",
          lat: userLoc[0],
          lng: userLoc[1],
          bound,
        });
      }
    }
  }, [ashtrays]);

  // When user location changes
  useEffect(() => {
    // Send location to website and update website search param
    connection.promise.then((parent) => {
      const websiteParams = {
        userLoc: userLocation.lat ? [userLocation.lat, userLocation.lng] : null,
      };
      parent.updateWebsiteUrlParam(websiteParams);
    });
    // Reverse geocode user location and get the address
    if(!geocoder) {
      setGeocoder(new window.google.maps.Geocoder());
    }
    if(!userLocation.address && userLocation.lat && geocoder) {
      geocoder.geocode(
        { location: { lat: userLocation.lat, lng: userLocation.lng } }, 
        (results, status) => {
          if (status === "OK" && results[0]) {
            setUserLocation({
              ...userLocation,
              address: results[0].formatted_address
            })
          } else {
              console.error("No results found");
          }
        }
      );
    }
  }, [userLocation]);

  // Get directions from Google when user location and/or ashtray change
  useEffect(() => {
    if (calcDirections) {
      if (
        !userLocation.lat ||
        !filteredAshtrays ||
        !filteredAshtrays.length > 0 ||
        !window.google
      ) {
        return;
      }
      if (!directionsService) {
        setDirectionsService(new window.google.maps.DirectionsService());
      }

      if (directionsService && directions) {
        const origin = {
          lat: userLocation.lat,
          lng: userLocation.lng,
        };
        const destination = {
          lat: filteredAshtrays[0].geometry.coordinates[1],
          lng: filteredAshtrays[0].geometry.coordinates[0],
        };
        directionsService.route(
          {
            origin,
            destination,
            travelMode: "WALKING",
          },
          (result, status) => {
            if (status === "OK") {
              setDirections({
                origin,
                destination,
                directions: result,
              });
            } else {
              console.error(`Error fetching directions ${result}`);
            }
          }
        );
      }
    }
  }, [userLocation, filteredAshtrays, directionsService]);

  // When user location is updated, calculate distance to ashtray
  useEffect(() => {
    if (!userLocation.lng || !userLocation.lat || !ashtrays) return;

    // Convert userLocation to a turf point
    const userPoint = point([userLocation.lng, userLocation.lat]);

    // Calculate each ashtray's distance to user location
    ashtrays.map((ashtray) => {
      const ashtrayPoint = point(ashtray.geometry.coordinates);
      const d = distance(userPoint, ashtrayPoint, { units: "meters" });
      const humanised_d = humaniseDistance(d);
      // Store calculated distance as value in meters and human readable format
      ashtray.properties.distance = d;
      ashtray.properties.distance_humanised = humanised_d;
      return ashtray;
    });

    // Filter ashtrays based on distance
    const nearbyAshtrays = ashtrays.filter((ashtray) => {
      return ashtray.properties.distance <= SEARCH_RADIUS;
    });
    if (updateResults) {
      const sortedNearbyAshtrays = [...nearbyAshtrays].sort((a, b) => {
        return a.properties.distance - b.properties.distance;
      });
      setFilteredAshtrays(sortedNearbyAshtrays);
    }
  }, [userLocation, ashtrays]);

  const clearSearch = () => {
    if (searchBoxContainerRef.current && searchBoxContainerRef.current.querySelector('input')) {
      searchBoxContainerRef.current.querySelector('input').value = "";
    }
  };

  const userLocationMessage = (address) => {
    let message;
    if (location.startsWith("/ashtrays/")) {
      message = "Showing directions from";
    } else {
      message = "Showing ashtrays near";
    }
    return ( 
          <p className={styles.currentLocation}>
            {message} <span className={styles.address}>{address}</span>
          </p>
    );
  };

  return (
    <div className={styles.search} data-visibility={ userLocation.lat === null }>
      { userLocation.address && 
          userLocationMessage(userLocation.address)
      }
      <div className={styles.container}>
        {showInput && (
          <h2 className={styles.searchHeading}>Find your nearest ashtray</h2>
        )}
        <div 
          className={styles.searchContainer}
          ref={searchBoxContainerRef}
        >
          <StandaloneSearchBox
            ref={searchBoxRef}
            onLoad={onSearchBoxLoaded}
            onPlacesChanged={onPlacesChanged}
            bounds={{
              north: -10.5,
              south: -43.5,
              east: 154,
              west: 111,
            }}
          >
            <div className={styles.inputContainer}>
              <input
                type="text"
                placeholder={
                  showInput
                    ? userLocation.lat ? "Change your location" : "Search by location"
                    : userLocation.lat ? "Change your location" : "Enter location for directions"
                }
                className={styles.searchInput}
              />
              <button className={styles.clearSearch} onClick={clearSearch}></button>
            </div>
          </StandaloneSearchBox>
          <button
            onClick={handleGeolocation}
            className={styles.geolocateButton}
          >
            Locate me
          </button>
        </div>
      </div>
    </div>
  );
};

export default Search;
