import React, { createContext, useState, useContext } from 'react';

const DirectionsContext = createContext([]);

export const useDirections = () => {
    return useContext(DirectionsContext);
};

export const DirectionsProvider = ({ children }) => {
    const [directions, setDirections] = useState({
      orign: null,
      destination: null,
      directions: null
    });

    return (
        <DirectionsContext.Provider value={[ directions, setDirections ]}>
            {children}
        </DirectionsContext.Provider>
    );
};
