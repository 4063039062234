import React, { useEffect, useRef, useState } from "react";

import { createRoot } from "react-dom/client";

// [Excluding Mapbox GL JS explicitly from transpilation](https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling)
// See https://stackoverflow.com/questions/66476925/unexpected-in-worker-loadermapbox-gl-dist-mapbox-gl-csp-worker
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import "mapbox-gl/dist/mapbox-gl.css";
import polyline from "@mapbox/polyline";

// Geo functions
import { point } from "@turf/helpers";
import distance from "@turf/distance";

// import { connectToParent } from "penpal";
import { Link, useLocation } from "wouter";

import { useUserLocation } from "../../UserLocationContext";
import { useDirections } from "../../DirectionsContext";
import { useAshtrays } from "../../AshtraysContext";
import { useFilteredAshtrays } from "../../FilteredAshtraysContext";

import {
  calculateCentroidAndBounds,
  calculateBounds,
  getMapAppLinks,
  humaniseDistance,
  convertHeicToJpg,
} from "../../utilities";

import { BOUND_PADDING } from "../../settings";

import styles from "./Map.module.scss";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.workerClass = MapboxWorker; // Wire up loaded worker to be used instead of the default

const Map = (props) => {
  const { ashtrays, loading } = useAshtrays();
  const { filteredAshtrays, setFilteredAshtrays } = useFilteredAshtrays();

  // Create map
  const [userLocation] = useUserLocation();
  const [directions] = useDirections();
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  const [selectedAshtray, setSelectedAshtray] = useState(null);
  const [photoLoading, setPhotoLoading] = useState(true);
  const [surroundingsPhoto, setPhoto] = useState(null);

  const [popupCoordinates, setPopupCoordinates] = useState(null);
  const [location] = useLocation();
  const [appLinks, setAppLinks] = useState(null);

  const ashtrayID = props.ashtrayID;
  const userLoc = props.userLoc;

  var popup = new mapboxgl.Popup({
    offset: {
      top: [10, -20],
    },
  });

  // When a new ashtray is selected, create a new tooltip
  useEffect(() => {
    if (selectedAshtray) {
      // Create an empty div element to render the tooltip
      const div = document.createElement("div");
      const root = createRoot(div);

      // if user location is know, show distance
      if (selectedAshtray) {
        const userPoint = userLocation.lat
          ? point([userLocation.lng, userLocation.lat])
          : null;
        const ashtrayPoint = point(selectedAshtray._geometry.coordinates);
        const d = userPoint
          ? distance(userPoint, ashtrayPoint, { units: "meters" })
          : null;
        const distance_humanised = d ? humaniseDistance(d) : null;

        // Photo
        setPhoto(null);
        convertHeicToJpg(
          JSON.parse(selectedAshtray.properties.photo).surroundings.url,
          setPhoto,
          setPhotoLoading
        );

        root.render(
          <div className={styles.ashtrayTooltipContainer}>
            {/* Hide photo until JPG version is available */}
            {/* <div className={styles.imageCrop}>
                {photoLoading ? (
                  <div className={styles.image}>
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="9"
                      color="#3A0DEE"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <img
                    className={styles.image}
                    src={surroundingsPhoto}
                    alt={'Photo of ashtray at ' + selectedAshtray.properties.name}
                  />
              )}
              </div> */}

            <h2 className={styles.ashtrayName}>
              {selectedAshtray.properties.name}
            </h2>
            <div className={styles.distanceButtonContainer}>
              {userLocation.lat && (
                <div className={styles.distance}>
                  {distance_humanised ? distance_humanised : ""}
                </div>
              )}
              <a
                href={`/ashtrays/${selectedAshtray.properties.id}${
                  userLoc
                    ? "?userLoc=" + userLoc
                    : "?userLoc=" + userLocation.lat + "," + userLocation.lng
                }`}
                className={styles.ashtrayLink}
              >
                Go &raquo;
              </a>
            </div>
          </div>
        );
      }

      if (popup) popup.remove();

      const clickedCoordinates = popupCoordinates
        ? popupCoordinates
        : selectedAshtray.geometry.coordinates.slice();

      // create popup
      popup.setLngLat(clickedCoordinates).setDOMContent(div).addTo(map.current);

      map.current.flyTo({
        center: clickedCoordinates,
        zoom: 18,
        essential: true,
      });
    }
  }, [selectedAshtray]);

  useEffect(() => {
    if (map.current) return; // Initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: process.env.REACT_APP_MAPBOX_STYLE,
      center: [151.2093, -33.8688],
      zoom: 10,
    });

    map.current.on("load", () => {
      // Directions
      map.current.addSource("directions", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        },
      });
      map.current.addLayer({
        id: "directions",
        type: "line",
        source: "directions",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#3A0DEE",
          "line-width": 8,
          "line-dasharray": [0.5, 1.2],
        },
      });

      // Load an image from an external URL.
      // Ashtray icon
      map.current.loadImage("/img/marker-hand-gray.png", (error, image) => {
        if (error) throw error;
        // Add the image to the map style.
        map.current.addImage("hand-gray", image);
      });

      map.current.loadImage("/img/marker-hand-red.png", (error, image) => {
        if (error) throw error;
        // Add the image to the map style.
        map.current.addImage("hand-red", image);
      });

      // User location icon
      map.current.loadImage("/img/marker-user-location.png", (error, image) => {
        if (error) throw error;
        // Add the image to the map style.
        map.current.addImage("userLocation", image);
      });

      // Ashtrays
      map.current.addSource("ashtrays", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });
      map.current.addLayer({
        id: "ashtrays",
        type: "symbol",
        source: "ashtrays",
        layout: {
          "icon-image": ashtrayID
            ? ["match", ["get", "id"], ashtrayID, "hand-red", "hand-gray"]
            : "hand-gray",
          "icon-allow-overlap": true,
          "icon-size": ashtrayID
            ? ["case", ["==", ["get", "id"], ashtrayID], 1, 0.7]
            : 1,
        },
      });

      if (map.current && map.current.getSource("ashtrays")) {
        map.current.getSource("ashtrays").setData({
          type: "FeatureCollection",
          features: ashtrays,
        });
      }

      // User location marker
      map.current.addSource("userLocation", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      map.current.addLayer({
        id: "userLocation",
        type: "symbol",
        source: "userLocation",
        layout: {
          "icon-image": "userLocation",
        },
      });

      map.current.on("click", "ashtrays", function (e) {
        // Close any existing popups
        if (popup) popup.remove();

        const feature = e.features[0];

        // Get coordinates from the feature.
        var coordinates = feature.geometry.coordinates.slice();
        setSelectedAshtray(feature);

        // Ensure the popup is shown over the clicked feature by moving the popup's anchor
        // to the top-left, bottom-left or bottom-right if necessary.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        setPopupCoordinates(coordinates);
      });

      // Generate map app links
      setAppLinks(getMapAppLinks(map.current.getCenter()), null);
    });
  }, []);

  useEffect(() => {
    // Ensure the map and the source are loaded before trying to update the source data
    if (map.current && map.current.getSource("ashtrays")) {
      map.current.getSource("ashtrays").setData({
        type: "FeatureCollection",
        features: ashtrays,
      });
    }
  }, [ashtrays]); // Listening for changes in the ashtrays state

  function addUserLocationMarker() {
    if (map.current && userLocation && map.current.getSource("userLocation")) {
      map.current.getSource("userLocation").setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [userLocation.lng, userLocation.lat],
        },
      });
    }
  }

  useEffect(() => {
    if (map.current && map.current.getSource("userLocation")) {
      map.current.getSource("userLocation").setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [userLocation.lng, userLocation.lat],
        },
      });
    }
  }, [userLocation]);

  useEffect(() => {
    // Display direction from user loation to an ashtray
    if (
      directions &&
      directions.directions &&
      filteredAshtrays &&
      filteredAshtrays.length > 0
    ) {
      if (map.current) {
        // Zoom to show directions
        if (map.current.getSource("directions")) {
          const directionsGeometry = polyline.toGeoJSON(
            directions.directions.routes[0].overview_polyline
          );
          const directionsCoordinates = polyline
            .decode(directions.directions.routes[0].overview_polyline)
            .map((coord) => [coord[1], coord[0]]);
          map.current.getSource("directions").setData({
            type: "Feature",
            properties: {},
            geometry: directionsGeometry,
          });
          const bounds = directionsCoordinates.reduce(
            (bounds, coord) => bounds.extend(coord),
            new mapboxgl.LngLatBounds(
              directionsCoordinates[0],
              directionsCoordinates[0]
            )
          );
          map.current.fitBounds(bounds, {
            padding: BOUND_PADDING,
          });
        }
        // Zoom to show areas around user location
        else {
          map.current.setCenter([userLocation.lng, userLocation.lat]);
          map.current.fitBounds(userLocation.bound);
        }
      }
      addUserLocationMarker();
      setAppLinks(
        getMapAppLinks(userLocation, {
          lat: filteredAshtrays[0].geometry.coordinates[1],
          lng: filteredAshtrays[0].geometry.coordinates[0],
        })
      );
    }
    // Zoom to ashtrays from the search result
    else if (filteredAshtrays && filteredAshtrays.length > 0) {
      const userLocationFeature = userLocation.lng
        ? [
            {
              type: "Feature",
              properties: {
                type: "userLocation",
              },
              geometry: {
                type: "Point",
                coordinates: [userLocation.lng, userLocation.lat],
              },
            },
          ]
        : [];
      if (userLocation.lat) addUserLocationMarker();

      const features = userLocationFeature.concat(filteredAshtrays);
      const subset = calculateCentroidAndBounds(features);
      map.current.setCenter([subset.centroid.x, subset.centroid.y]);
      if (filteredAshtrays.length > 1) {
        const bound = [
          [subset.bounds.minX, subset.bounds.minY],
          [subset.bounds.maxX, subset.bounds.maxY],
        ];
        map.current.fitBounds(bound, {
          padding: BOUND_PADDING,
        });
        setAppLinks(
          getMapAppLinks(null, {
            lat: filteredAshtrays[0].geometry.coordinates[1],
            lng: filteredAshtrays[0].geometry.coordinates[0],
          })
        );
      } else {
        const bound = calculateBounds(subset.centroid.y, subset.centroid.x);
        map.current.fitBounds(bound, {
          padding: BOUND_PADDING,
        });

        // Generate map app links
        setAppLinks(
          getMapAppLinks(null, {
            lat: subset.centroid.y,
            lng: subset.centroid.x,
          })
        );
      }
    }
    // No nearby ashtrays found, zoom user location
    else if (userLocation.lat && userLocation.lng) {
      map.current.setCenter([userLocation.lng, userLocation.lat]);
      map.current.fitBounds(userLocation.bound);
      addUserLocationMarker();

      // Generate map app links
      setAppLinks(getMapAppLinks(userLocation, null));
    }
  }, [userLocation, filteredAshtrays, directions]);

  return (
    <div>
      <div ref={mapContainerRef} style={{ width: "100%", height: "400px" }} />
      {appLinks && location !== "/" && (
        <div className={styles.appLinksContainer}>
          <span className={styles.appLinksLabel}>Open full directions in:</span>
          <a
            className={styles.appLink}
            href={appLinks.google}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Maps
          </a>
          <a
            className={styles.appLink}
            href={appLinks.apple}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Maps
          </a>
        </div>
      )}
    </div>
  );
};

export default Map;
