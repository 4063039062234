import { useEffect } from "react";

// Context
import { useUserLocation } from "../../UserLocationContext";

// Components
import Search from '../../components/Search/Search';
import Results from '../../components/Results/Results';

// Components
import PageQRCode from '../../components/PageQRCode/PageQRCode'

import { calculateBounds } from "../../utilities";

function Home(props) {
  const userLoc = props.userLoc;

  const [userLocation, setUserLocation] = useUserLocation();

  useEffect(() => {
    const appUrlParams = new URLSearchParams(window.location.search);
    const userLoc = appUrlParams.get("userLoc");
    if (userLoc && !userLocation.lat) {
      const lat = parseFloat(userLoc.split(",")[0]);
      const lng = parseFloat(userLoc.split(",")[1]);
      const bound = calculateBounds(lat, lng);
      // debugger
      setUserLocation({
        address: "",
        lat,
        lng,
        bound,
      });
    }
  }, [window.location.search]);

  return (
    <div className="App">
      <Search userLoc={userLoc} />
      <Results userLoc={userLoc} />
      <PageQRCode />
    </div>
  )
}

export default Home;