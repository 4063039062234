import React, { createContext, useState, useContext } from 'react';

const FilteredAshtraysContext = createContext([]);

export const useFilteredAshtrays = () => {
    return useContext(FilteredAshtraysContext);
};

export const FilteredAshtraysProvider = ({ children }) => {
    const [filteredAshtrays, setFilteredAshtrays] = useState([]);

    return (
        <FilteredAshtraysContext.Provider value={{ filteredAshtrays, setFilteredAshtrays }}>
            {children}
        </FilteredAshtraysContext.Provider>
    );
};
