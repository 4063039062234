import React, { createContext, useState, useEffect, useContext } from 'react';

import { fetchRecords } from './components/Airtable/AirtableHelper';

const AshtraysContext = createContext([]);

export const useAshtrays = () => {
    return useContext(AshtraysContext);
};

export const AshtraysProvider = ({ children }) => {
    const [ashtrays, setAshtrays] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
          try {
            const data = await fetchRecords('Ashtray Table');
            setAshtrays(data);
            setLoading(false);
          } catch (err) {
            setError(err);
            setLoading(false);
          }
      };

      fetchData();
    }, []); // This useEffect will run once when the context is first used (similar to componentDidMount).

    return (
        <AshtraysContext.Provider value={{ ashtrays, loading, setAshtrays }}>
            {children}
        </AshtraysContext.Provider>
    );
};
