import React, { useEffect, useRef, useState } from "react";

import { useUserLocation } from "../../UserLocationContext";
import { useAshtrays } from "../../AshtraysContext";
import { useFilteredAshtrays } from "../../FilteredAshtraysContext";

import { SEARCH_RADIUS } from "../../settings";

import ListItem from "../ListItem/ListItem";

// Styles
import styles from "./List.module.scss";

const List = (props) => {
  const userLoc = props.userLoc;

  const { ashtrays, loading } = useAshtrays();
  const { filteredAshtrays } = useFilteredAshtrays();

  const [userLocation] = useUserLocation();
  const listContainerRef = useRef(null);
  const listRef = useRef(null);

  const [results, setResults] = useState();
  const [itemsToShow, setItemsToShow] = useState(5);

  useEffect(() => {
    // Do something when ashtray data is fetched
    if (ashtrays || filteredAshtrays) {
      setResults(
        filteredAshtrays && filteredAshtrays.length > 0
          ? filteredAshtrays
          : ashtrays
      );
    }
  }, [ashtrays, filteredAshtrays]); // Listening for changes in the ashtrays state

  useEffect(() => {
    if (userLocation.lat && userLocation.lng) {
      // filter list
    }
  }, [userLocation]);

  if (loading) {
    return (
      <div ref={listContainerRef} style={{ width: "100%", height: "400px" }}>
        Loading...
      </div>
    );
  }

  // const results =

  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + 5); // increase number of items by 5
  };

  const getResultText = () => {
    if (results) {
      if (userLocation.lat && filteredAshtrays.length > 0) {
        const count = results.length;
        if (count === 0) {
          return "We found no ashtrays";
        } else if (count === 1) {
          return "We found 1 ashtray";
        } else {
          return `We found ${count} ashtrays`;
        }
      } else if (userLocation.lat && filteredAshtrays.length === 0) {
        return "Sorry, no ashtrays were found within 200 metres. There are probably ashtrays around but they aren't in the National Ashtray database yet.";
      } else {
        return "Please enter your location to find the nearest ashtrays.";
      }
    }
  };

  return (
    <div ref={listContainerRef} className={styles.listContainer}>
      {results && (
        <React.Fragment>
          <p className={styles.listLabel}>
            {getResultText()}
            {userLocation.lat && filteredAshtrays.length > 0
              ? " within " + SEARCH_RADIUS + " metres:"
              : ""}
          </p>

          <ul className={styles.resultsList}>
            {filteredAshtrays.length > 0 &&
              results.slice(0, itemsToShow).map((ashtray, index) => {
                return (
                  <li
                    key={"ashtray-list-" + index}
                    className={styles.resultsItem}
                  >
                    <ListItem key={index} ashtray={ashtray} userLoc={userLoc} />
                  </li>
                );
              })}
          </ul>

          {filteredAshtrays.length > 0 && itemsToShow < results.length && (
            <button className={styles.loadMore} onClick={handleLoadMore}>
              Load 5 more results
            </button>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default List;
